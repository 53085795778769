import React from 'react';
import FilterComponent from '../components/FilterComponent';

const FilterPage = () => {
    return (
        <div className="p-6 mx-auto max-w-2xl bg-gray-100 rounded-lg shadow-md mt-5">
            <h1 className="text-2xl font-bold text-center mb-4">Filter Posts</h1>
            <FilterComponent />
        </div>
    );
};

export default FilterPage;