import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const FilterComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState({
        helperName: '',
        category: '',
        // Add more filter criteria as needed
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setFilters({
            category: params.get('category') || '',
            // Add more filter criteria as needed
        });
    }, [location.search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value,
        });
        sessionStorage.setItem(name, value); // Save filter criteria to sessionStorage
    };

    const applyFilters = () => {
        const params = new URLSearchParams();
        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                params.append(key, filters[key]);
            }
        });
        navigate(`/posts?${params.toString()}`); // Navigate to the Posts page with URL parameters
    };

    return (
        <div className="flex flex-col gap-4">
            <select
                name="category"
                value={filters.category}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Nationality</option>
                <option value="Myanmar">Myanmar</option>
                <option value="India">India</option>
                <option value="Indonesia">Indoneisa</option>
                <option value="Filipines">Filipines</option>
            </select>
            <select
                name="sgExp"
                value={filters.sgExp}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Experience</option>
                <option value="New to SG">New</option>
                <option value="Transfer">Transfer(in SG)</option>
                <option value="Ex-Sin">Ex-Singapore</option>
                <option value="Ex-Arab">Ex-Arab</option>
                <option value="Ex-Home">Maid in own country</option>
            </select>
            <select
                name="language1"
                value={filters.language1}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Dialect</option>
                <option value="Burmese">Burmese</option>
                <option value="Tamil">Tamil</option>
                <option value="Punjabi">Punjabi</option>
                <option value="Mizo">Mizoram</option>
                <option value="Nepali">Darjeeling</option>
                <option value="Malayalam">Malayalam</option>
                <option value="Telugu">Telugu</option>
                <option value="Gujrati">Gujrati</option>
            </select>
            <select
                name="language2"
                value={filters.language2}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"            >
                <option value="">Other Languages</option>
                <option value="Fair English">English</option>
                <option value="Chinese">Chinese</option>
                <option value="Malay">Malay</option>
                <option value="Hindi">Hindi</option>
            </select>

            <select
                name="marriage"
                value={filters.marriage}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Marital status</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Married w kids">Married with children</option>
                <option value="divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Separated">Separated</option>
            </select>
            <select
                name="religion"
                value={filters.religion}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Religion</option>
                <option value="Christian">Christian</option>
                <option value="Buddhist">Buddhist</option>
                <option value="Hindu">Hindu</option>
                <option value="Muslim">Muslim</option>
                <option value="Sikh">Sikh</option>
            </select>
            <select
                name="neg"
                value={filters.neg}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
            >
                <option value="">Special care</option>
                <option value="Infant,Child">Infant</option>
                <option value="Child,Elder">Child</option>
                <option value="Eldercare">Eldercare</option>
            </select>

            {/* Add more filter inputs as needed */}
            <button
                onClick={applyFilters}
                className="p-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
                Apply Filters
            </button>
        </div>
    );
};

export default FilterComponent;